<template>
    <div class="wrapper-show">
        <el-table
                :data="boardData" class="list-table" header-cell-class-name="list-header"
                row-class-name="list-row" height="315" empty-text="데이터가 없습니다."
                @row-click="movePage"
        >
            <el-table-column
                    class="table_no"
                    type="index"
                    label="No."
                    width="100"
                    class-name="num text-center"
                    :index="tableIdx"
            ></el-table-column>
            <el-table-column label="제목" width="" prop="wr_subject"></el-table-column>
            <el-table-column label="등록일" width="150">
                <template slot-scope="scope">
                    {{ returnDateTime(scope.row.created_at) }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import dateMixins from "@/mixins/dateMixins";

export default {
    name: "BoardListDefaultLayout",
    mixins: [dateMixins],
    components: {},
    inject: ['setPage'],
    provide() {
        return {}
    },
    props: {
        boardData: {},
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
    },
    data() {
        return {
            boardKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            this.$router.push(`/image/${this.boardKey}/${data.wr_id}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

let timeInterval = null;

export default {
    data() {
        return {
            timer: null,
            diffDay: 0,
            diffTime: 0,
            resultType: false,
            currentTime: '',
        }
    },
    methods: {
        returnDateFormat(date = null, format = 'YYYY-MM-DD') {
            if (!date) {
                return '';
            }
            return this.$moment(date).format(format)
        },
        returnDateTime(date = null) {
            if (!date) {
                return '';
            }
            return this.$moment(date).format('YYYY-MM-DD HH:mm')
        },
        returnApplyDate(end_date, start_date) {
            let s_time = this.$moment(start_date).format('YYYY. MM. DD')
            let e_time = this.$moment(end_date).format('YYYY. MM. DD')
            return `${s_time} ~ ${e_time}`
        },
        returnReleaseDate(time) {
            let now = this.moment();
            let r_time = this.$moment(time).format('YYYY. MM. DD')
            return r_time;
        },
        returnDate(date = null) {
            if (!date) {
                return '';
            }
            return this.$moment(date).format('YYYY-MM-DD')
        },
        getDifferenceTime(endTime, currentTime) {
            let current = this.$moment(currentTime)
            let end = this.$moment(endTime)
            let diff = current.diff(end, 'day')
            if (diff > -1) {
                let s_diff = current.diff(end, 'second')
                diff = s_diff > 0 ? 0 : Math.abs(s_diff)
            } else {
                return `D-${Math.abs(diff) + 1}`
            }
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour}:${min}`
        },
        returnDiffTime(date) {
            return this.reduceTime(date)
        },
        getCurrentTime() {
            this.currentTime = this.$moment()
        },
        returnDateUnix(date, format = 'YYYY-MM-DD HH:mm') { // 타임스탬프를 데이트로 변환
            if (!date) {
                return '';
            }
            return this.$moment.unix(date).locale('ko').format(format)
        },
        returnDateTimeStamp(date) { //데이트를 타임스탬프로 변환
            if (!date) {
                return '';
            }
            return this.$moment(date).format("X")
        },
        reduceTime(date) {
            let current = this.$moment();
            let release = this.$moment(date).add(1, 'd')
            // return release.format('DD')
            let diff = current.diff(release, 'd')
            if (diff > -1) {
                let s_diff = current.diff(release, 'second')
                diff = s_diff > 0 ? 0 : Math.abs(s_diff)
            } else {
                return `D-${Math.abs(diff)}`
            }
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            // sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min}`
        },

    },
}
